import React, { useState, useEffect } from "react";
import { Text, Title, Metric, Callout } from "@tremor/react";
import { HiExclamation, HiX, HiOutlineCheck } from "react-icons/hi";
import axios from "axios";

export default function MachineStatusCallout({ machineData }) {
  const [machine, setMachine] = useState(machineData);
  const [statusColor, setStatusColor] = useState("red");
  const [statusText, setStatusText] = useState("Offline");

  useEffect(() => {
    setMachine(machineData);
  }, [machineData]);

  const root = "https://api.brandonwortman.com";

  useEffect(() => {
    axios
      .get(root + "/dashboard/machine/status")
      .then((response) => {
        if (response.data.status) {
          setStatusColor("green");
          setStatusText("Machine Online");
        } else if (response.data.lastConnected) {
          const lastConnectedTime = new Date(response.data.lastConnected);
          const currentTime = new Date();
          const timeDifference = currentTime - lastConnectedTime;
          const minutesDifference = Math.floor(timeDifference / 60000);
          if (minutesDifference <= 5) {
            setStatusColor("yellow");
            setStatusText(
              "Last Connected: " + minutesDifference + " minutes ago"
            );
          } else {
            setStatusColor("red");
            setStatusText("Machine Offline");
          }
        } else {
          setStatusColor("red");
          setStatusText("Machine Offline");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Callout
      color={statusColor}
      icon={statusColor === "red" ? HiX : HiOutlineCheck}
      title={statusText}
    />
  );
}
