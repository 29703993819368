import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Flex,
  Text,
  Title,
  Metric,
  ProgressBar,
  Callout,
  Divider,
  Bold,
  Italic,
  TextInput,
} from "@tremor/react";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { HiArrowsExpand } from "react-icons/hi";
import { HiExclamation, HiX, HiOutlineCheck } from "react-icons/hi";

export default function BottleCard({ bottleData, updateBottle }) {
  const [bottle, setBottle] = useState(bottleData);
  const [isOpen, setIsOpen] = useState(false);
  const [ozRemaining, setOzRemaining] = useState(bottleData.ozRemaining);
  const [ozRemainingInput, setOzRemainingInput] = useState(
    bottleData.ozRemaining.toString()
  );
  const [ozCapacity, setOzCapacity] = useState(bottleData.ozCapacity);
  const [ozCapacityInput, setOzCapacityInput] = useState(
    bottleData.ozCapacity.toString()
  );
  const [costPerOz, setCostPerOz] = useState(bottleData.costPerOz);
  const [costPerOzInput, setCostPerOzInput] = useState(
    bottleData.costPerOz.toString()
  );
  const [switchEnabled, setSwitchEnabled] = useState(bottleData.status);

  const closeModal = () => {
    setIsOpen(false);
    resetInputValues();
  };
  const openModal = () => {
    setIsOpen(true);
    loadInitialValues();
  };

  const resetInputValues = () => {
    setOzRemainingInput(ozRemaining.toString());
    setOzCapacityInput(ozCapacity.toString());
    setCostPerOzInput(costPerOz.toString());
  };

  const loadInitialValues = () => {
    setOzRemainingInput(bottleData.ozRemaining.toString());
    setOzCapacityInput(bottleData.ozCapacity.toString());
    setCostPerOzInput(bottleData.costPerOz.toString());
  };

  const saveData = () => {
    const newOzRemaining = parseFloat(ozRemainingInput);
    const newOzCapacity = parseFloat(ozCapacityInput);
    const newCostPerOz = parseFloat(costPerOzInput);

    // Call the updateBottle function passed from the parent
    updateBottle(bottle._id, {
      ozRemaining: newOzRemaining,
      ozCapacity: newOzCapacity,
      costPerOz: newCostPerOz,
      status: switchEnabled,
    });
    closeModal();
  };

  // If bottleData prop changes, update state
  useEffect(() => {
    setBottle(bottleData);
  }, [bottleData]);

  const progressValue = (bottle.ozRemaining / bottle.ozCapacity) * 100;
  const progressColor = progressValue < 15 ? "red" : "green";
  const statusColor = bottle.status ? "green" : "red";

  return (
    <>
      <Card
        className="max-w-xs mx-auto"
        decoration="top"
        decorationColor={statusColor}
      >
        {/* <Callout color={statusColor} icon={ExclamationIcon} title="Test" /> */}
        <Title>{`${bottle._id}: ${bottle.name}`}</Title>
        <Metric>{bottle.ozRemaining.toFixed(2)} oz</Metric>
        <ProgressBar value={progressValue} color={progressColor}></ProgressBar>
        <Italic>Max Capacity: {bottle.ozCapacity.toFixed(2)} oz</Italic>

        <Button
          icon={HiArrowsExpand}
          className="mt-4 w-full bg-white border-gray-200 text-gray-500 hover:bg-gray-50 hover:border-gray-300"
          onClick={openModal}
        >
          Edit and View More
        </Button>
      </Card>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-25"></div>
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-full max-w-xl transform overflow-hidden ring-tremor bg-white
                                    p-6 text-left align-middle shadow-tremor transition-all rounded-xl"
                >
                  {/* <Callout
                    color={statusColor}
                    icon={ExclamationIcon}
                    title="Test"
                  /> */}
                  <Title>{`${bottle._id}: ${bottle.name}`}</Title>
                  <Metric>{bottle.ozRemaining.toFixed(2)} oz</Metric>
                  <ProgressBar
                    value={progressValue}
                    color={progressColor}
                  ></ProgressBar>
                  <Italic>
                    Max Capacity: {bottle.ozCapacity.toFixed(2)} oz
                  </Italic>
                  <Divider />

                  <Flex className="max-w-none justify-between mt-4">
                    <div>
                      <Bold>Capacity Remaining: </Bold>
                      <TextInput
                        placeholder={`${bottle.ozRemaining.toFixed(2)} oz`}
                        value={ozRemainingInput}
                        onChange={(e) => setOzRemainingInput(e.target.value)}
                      />
                    </div>
                    <div>
                      <div>
                        <Bold>Total Capacity: </Bold>
                        <TextInput
                          placeholder={`${bottle.ozCapacity.toFixed(2)} oz`}
                          value={ozCapacityInput}
                          onChange={(e) => setOzCapacityInput(e.target.value)}
                        />
                      </div>
                    </div>
                  </Flex>
                  <Flex className="max-w-none justify-between mt-4">
                    <div>
                      <Bold>Price Per Oz: </Bold>
                      <TextInput
                        placeholder={`$${bottle.costPerOz.toFixed(2)}`}
                        value={costPerOzInput}
                        onChange={(e) => setCostPerOzInput(e.target.value)}
                      />
                    </div>
                    <div>
                      <div>
                        <Bold>Enable/Disable:</Bold>
                        <Switch
                          checked={switchEnabled}
                          onChange={setSwitchEnabled}
                          className={`${
                            switchEnabled ? "bg-green-500" : "bg-red-500"
                          } relative inline-flex h-6 w-11 items-center rounded-full`}
                        >
                          <span className="sr-only">Enable bottle</span>
                          <span
                            className={`${
                              switchEnabled ? "translate-x-6" : "translate-x-1"
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                          />
                        </Switch>
                      </div>
                    </div>
                  </Flex>

                  <Flex className="justify-between mt-4">
                    <Button
                      icon={HiX}
                      className="mt-2 bg-red-500 border-gray-200 text-white hover:text-gray-500 hover:bg-gray-50 hover:border-red-500"
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      icon={HiOutlineCheck}
                      className="mt-2 bg-green-500 border-gray-200 text-white hover:bg-gray-50 hover:text-gray-500 hover:border-green-500"
                      onClick={saveData}
                    >
                      Save
                    </Button>
                  </Flex>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
