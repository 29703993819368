"use client";
import { useState, useEffect } from "react";
import {
  Grid,
  Title,
  Text,
  Tab,
  TabList,
  TabGroup,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import MachineStatusCallout from "./MachineStatusCallout";
import BottleCardModal from "./BottleCardModal";
import axios from "axios";

const root = "https://api.brandonwortman.com";

export default function Dashboard() {
  const [bottleData, setBottleData] = useState([]);

  // useEffect(() => {
  //   fetch("https://api.brandonwortman.com/dashboard/data")
  //     .then((response) => response.json())
  //     .then((data) => setBottleData(data))
  //     .catch((error) => console.error(error));
  // }, []);

  useEffect(() => {
    axios
      .get(root + "/dashboard/bottle/all")
      .then((response) => setBottleData(response.data))
      .catch((error) => console.error(error));
  }, []);

  const updateBottle = async (bottleId, updatedData) => {
    try {
      const response = await axios.put(
        `${root}/dashboard/bottle/${bottleId}`,
        updatedData
      );
      console.log(response);
      // Update the state with the new bottle data
      setBottleData(
        bottleData.map((bottle) =>
          bottle._id === bottleId ? { ...bottle, ...updatedData } : bottle
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main className="px-12 py-12">
      <Title>Beverage Machine Dashboard</Title>
      <Text>Welcome, #NAME.</Text>
      <MachineStatusCallout
        machineData={{
          lastConnected: "	2023-07-20T13:37:57+0000",
          status: true,
        }}
      />
      <TabGroup className="mt-6">
        <TabList>
          <Tab>Overview</Tab>
          <Tab>Detail</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Grid numItemsMd={2} numItemsLg={3} className="gap-6 mt-6">
              {bottleData.map((bottle, index) => (
                <BottleCardModal
                  key={index}
                  bottleData={bottle}
                  updateBottle={updateBottle}
                >
                  {/* Placeholder to set height */}
                  <div className="h-28" />
                </BottleCardModal>
              ))}
            </Grid>
            <div className="mt-6"></div>
          </TabPanel>
          <TabPanel>
            <div className="mt-6"></div>
            <Text>Detail</Text>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </main>
  );
}
